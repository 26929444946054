import * as React from 'react'
import styled from '@emotion/styled'
import { HomeButton } from '../atoms/HomeButton'
import { Hamburger } from '../../side-navigation/atoms/Hamburger'
import { QuickLinks } from '../molecules/QuickLinks'
import { LazyLoadingSpinner } from '../../../common/components/LazyLoadingSpinner'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { useLogInState } from '../../auth/authState'
import { transientOptions } from '../../../common/style/transientOptions'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import { theme } from '../../../styles/theme'
import { useAccount } from '../../auth/hooks/useAccount'
import { useDevTools } from '../../../hooks/useDevTools'
import { CartNavItem } from '../atoms/cart/CartNavItem'
import { isPointingToProd } from '../../../env/utils/isPointingToProd'

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const MainNavContent = styled('div', transientOptions)<{}>`
  color: ${theme.colors.grayscalewhite};
  display: flex;
  position: relative;
  z-index: 1;
  background-color: ${theme.colors.primary600};
  justify-content: space-between;
  align-items: center;
  padding: env(safe-area-inset-top) var(--nav-side-padding) 0;
  height: calc(48px + env(safe-area-inset-top));
  display: flex;
  justify-content: center;
`

const RightSideIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogInSignUpButtons = styled(UnstyledButton)`
  text-align: center;
  font-family: ${theme.fonts.realizeMe};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: ${theme.colors.primary200};
  height: 48px;

  :hover {
    color: ${theme.colors.grayscalewhite};
  }

  ${tabletDownMq} {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.48px;
  }
`

const LogInSignUpWrapper = styled('div', transientOptions)`
  display: flex;
  align-items: center;
`

export const navbarId = 'navbar'
const LeftSide = styled.div`
  display: flex;
  align-items: center;
`

export function AxoNavContent() {
  const { isAuthenticated, isLoading } = useAccount()

  const [, setLogInState] = useLogInState()

  const [{ airplaneMode }] = useDevTools()
  const showFullNav = isAuthenticated || airplaneMode

  return (
    <MainNavContent>
      <ContentContainer>
        <LeftSide>
          <HomeButton />
          <QuickLinks />
        </LeftSide>
        {isLoading ? (
          <LazyLoadingSpinner isSmall />
        ) : (
          <RightSideIcons>
            {!isPointingToProd && <CartNavItem />}
            {showFullNav ? (
              <>
                <Hamburger />
              </>
            ) : (
              <RightSideIcons>
                <LogInSignUpWrapper>
                  <LogInSignUpButtons
                    style={{ paddingRight: 3, paddingLeft: 8 }}
                    onClick={() =>
                      setLogInState((s) => ({
                        ...s,
                        isLoggingIn: true,
                      }))
                    }
                  >
                    log In
                  </LogInSignUpButtons>
                </LogInSignUpWrapper>
              </RightSideIcons>
            )}
          </RightSideIcons>
        )}
      </ContentContainer>
    </MainNavContent>
  )
}
